import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div className='py-40 container mx-auto max-w-3xl px-6 sm:px-8 lg:px-8 text-center'>
      <h1>Page Not Found</h1>
      <p className='font-black font-serif text-green-800 text-6xl'>404</p>
      <p>You're on the road to nowhere</p><Link to='/' className='button text-green-800 bg-yellow-200 border-green-900 hover:bg-green-700 hover:text-yellow-200 mt-3' href='/'>Back to the homepage</Link>
    </div>
  </Layout>
)

export default NotFoundPage
